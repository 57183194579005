import aboutImage from './assets/about.jpg';
import ameliaImage from './assets/amelia.jpg';
import gabrielleImage from './assets/gabrielle.jpg';
import marinaImage from './assets/marina.jpg';
import zackImage from './assets/zack.jpg';
import kayaImage from './assets/kaya.jpg';
import liamImage from './assets/liam.jpg';

const About = () => {
  const people = [
    {
      name: "Amelia McCluskey",
      pronouns: "she/her",
      image: ameliaImage,
      description: (
        <>
          Amelia is the coordinator of TVFF and is a U3
          Cultural Studies and World Cinemas student
          at McGill. Her favourite movies are <i>Thelma
          and Louise</i> and <i>Petite Maman</i>. You can check
          out her Letterboxd <a href="https://letterboxd.com/amcclux/" target="_blank" rel="noreferrer" className="underline">here!</a>
        </>
      )
    },
    {
      name: "Gabrielle Cole",
      pronouns: "she/her",
      image: gabrielleImage,
      description: (
        <>
          Gabrielle is a jury member and assistant
          coordinator at TVFF and holds a BA in English
          literature from McGill University. Her
          favourite movies include <i>Se7en</i> and <i>The Last
          Days of Disco</i>. Her username on Letterboxd
          is <a href="https://letterboxd.com/starfleeet/" target="_blank" rel="noreferrer" className="underline">@starfleeet</a>.
        </>
      )
    },
    {
      name: "Marina Marshall",
      pronouns: "she/her",
      image: marinaImage,
      description: (
        <>
          Marina is a jury member and assistant
          coordinator at TVFF, and is a U3 McGill
          student in Cultural Studies and History.
          Some of her favourite movies include <i>Cinema
          Paradiso</i> and <i>The Faculty</i>. You can find her
          on Letterboxd <a href="https://letterboxd.com/marinamx5/" target="_blank" rel="noreferrer" className="underline">@marinamx5</a>.
        </>
      )
    },
    {
      name: "Kaya Davies",
      pronouns: "she/her",
      image: kayaImage,
      description: (
        <>
          Kaya is a Jury Member and Artistic and 
          Promotional Designer for TVFF. She is an artist 
          and graphic designer in her final year of 
          English Cultural Studies and Communications at McGill.
          Her favourite movies include Challengers and Mad Max: 
          Fury Road. Find her on letterboxd <a href="https://letterboxd.com/KayaD/" target="_blank" rel="noreferrer" className="underline">@KayaD</a> if 
          you want to do that. 
        </>
      )
    },
    {
      name: "Liam Foese",
      pronouns: "he/him",
      image: liamImage,
      description: (
        <>
          Liam is a TVFF jury member in his final year of 
          English Literature at McGill.  His favourite movies
          are Ponyo and Fargo. He doesn’t have a letterboxd 
          and instead forgets every movie he’s ever watched when asked.
        </>
      )
    },
    {
      name: "Zack Steine",
      pronouns: "he/him",
      image: zackImage,
      description: (
        <>
          Zack is a fourth year computer science
          student at UofT. He made this website!<br/><br/>
          He has Letterboxd but he’s shy so please
          don’t follow him.
        </>
      )
    }
  ];

  return (
    <div className="flex flex-col justify-start w-full gap-4 sm:items-center lg:gap-8 xl:gap-10">
      <img src={aboutImage} alt="" className="w-full sm:w-3/4"/>
      <div className="about flex flex-col gap-4 sm:w-3/4 sm:gap-3 lg:gap-6 xl:gap-8">
        <h1 className="font-bold text-3xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">ABOUT TWELVE VACANCIES</h1>
        <p className="text-sm sm:text-sm md:text-md lg:text-base xl:text-lg 2xl:text-xl">
          Twelve Vacancies Film Festival is a non-profit, student-run
          experimental and horror short film festival based at McGill University.
          Our mission is to create a platform for emerging youth filmmakers to
          share their work, and to strengthen the McGill and greater Montreal
          community through meaningful engagement with art. Twelve
          Vacancies Film Festival was founded in 2023 in collaboration with The
          Veg Magazine. 
          <br/><br/>
          TVFF is interested in screening visually innovative, engaging films. In
          the past, our jury has chosen both narrative genre films and expressive
          experimental films, with the goal of showcasing a range of mediums.
          <br/><br/>
          Our programming is decided by a student jury, who select twelve short
          films for our screening. Films are selected from filmmakers all over the
          world who are either under 25 years old or are students.
        </p>
      </div>
      <div className="our-team flex flex-col justify-start items-start gap-4 sm:w-3/4 lg:gap-8 xl:gap-14 ">
        <h2 className="font-bold text-2xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">OUR TEAM</h2>
        {
          people.map(person => {
            return (
              <div className="flex flex-row items-start gap-4 lg:gap-6 xl:gap-8">
                <img src={person.image} alt="" className="w-1/3 h-auto object-contain"/>
                <div className="flex flex-col w-2/3 gap-3 xl:gap-5">
                  <h3 className="text-sm font-bold sm:text-xs md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">{person.name} ({person.pronouns})</h3>
                  <p className="text-sm sm:text-sm md:text-md lg:text-base xl:text-lg 2xl:text-xl">
                    {person.description}
                  </p>
                </div>
              </div>
            )
          }
          )
        }
      </div>
    </div>
  );
}

export default About;