import submissionClosed from './assets/submissions_closed.jpg';

const Submit = () => {
  return (
    <div className="flex flex-col items-center gap-4">
      <h1 className="text-2xl mb-1 font-bold sm:text-1xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">SUBMISSIONS ARE CLOSED</h1>
      <div className="flex flex-col text-sm text-center mb-6 sm:text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-xl w-3/5 gap-3">
        <p>Thanks to all who submitted to our Fall 2024 season! Check back in January to find out how to submit our next season.</p>
        <p>This year's films will be screened on November 22nd at 3475 Rue Peel.</p>
        <p>If you have any questions, don't hestitate to contact us 
          at <a href="mailto:twelvevacanciesfilmfest@gmail.com" className="underline">twelvevacanciesfilmfest@gmail.com</a> </p>
      </div>
      <img src={submissionClosed} alt="" className="w-1/2"/>
    </div>
  );
}

export default Submit;